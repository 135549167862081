<template>
  <!-- 日志管理 -->
  <div class="purchaseManage">
    <!-- 板块中心区域 -->
    <div class="purchaseManage_mid">
      <div class="purchaseManage_header">
        <!-- 标题 -->
        <div class="title">
          <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" />
          <p v-for="(item, index) in $route.meta" :key="index">{{ item }}</p>
        </div>
        <!-- 选项框和按钮 -->
        <div class="option_btn">
          <div class="options">
            <!-- Element ui--Select 选择器 -->
            <!-- 用户名 -->
            <template>
              <span style="margin: 0 0.8vw 0 1.92vw">日志类型</span>
              <el-select v-model="listQuery.type" placeholder="请选择">
                <el-option v-for="item in [{ value: '-1', label: '全部' }, { value: '1', label: '登录' }]" :key="item.value"
                  :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
            <!-- 手机号 -->
            <template>
              <span style="margin: 0 0.8vw 0 1.92vw">ip地址</span>
              <el-input v-model="listQuery.uip" placeholder="请输入ip地址" class="production"></el-input>
            </template>
          </div>
        </div>
      </div>

      <!-- 表格区域 -->
      <div class="gateway_form">
        <div class="lists">
          <!-- Element ui -- Form表单 -->
          <template>
            <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName"
              :header-cell-style="{ background: '#4888FF' }" :max-height="tableHeight">
              <!-- 索引序号 -->
              <el-table-column type="index" width="80" label="序号"></el-table-column>
              <!-- 用户名 -->
              <el-table-column prop="user" label="用户名"> </el-table-column>
              <!-- 登录账号 -->
              <!-- <el-table-column prop="loginAccount" label="登录账号">
              </el-table-column> -->
              <!-- 手机号码 -->
              <el-table-column prop="phone" label="手机号码"> </el-table-column>
              <!-- 登录时间 -->
              <el-table-column prop="time" label="登录时间"> </el-table-column>
              <!-- 登录IP -->
              <el-table-column prop="ip" label="登录IP" width="300">
              </el-table-column>
            </el-table>
          </template>
          <!-- Element ui--Pagination分页 -->
          <template>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="listQuery.page" :page-sizes="[100, 200, 300, 400]" :page-size="listQuery.size"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logManagement } from "../../utils/api";
export default {
  computed: {},
  data() {
    return {
      tableHeight: 0,
      tableData: [],
      total: 0,
      listQuery: {
        page: 1,
        size: 100,
        uip: '', //ip地址
        type: '-1'// 日志类型, -1全部 1登录
      },
    };
  },
  created() {
    this.tableHeight = document.documentElement.clientHeight - 300;
    this.getList();
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.tableHeight = document.documentElement.clientHeight - 300;
    })
  },
  methods: {
    //获取列表数据
    getList() {
      logManagement(this.listQuery).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    //分页事件
    handleSizeChange(size) {
      this.listQuery.page = 1
      this.listQuery.size = size;
    },
    handleCurrentChange(page) {
      this.listQuery.page = page;
    },
    //隔行变色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    },
  },
  watch: {
    listQuery: {
      handler: function () {
        this.getList()
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}

.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}

.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-size: 14px;
  font-weight: normal;
}

.lists .el-pagination {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}

.el-table /deep/ .el-table__cell {
  text-align: center;
  padding: 16px 0;
}

.purchaseManage {
  height: 100%;
  margin: -30px 0 0 0;
}

.purchaseManage .purchaseManage_mid {
  height: 100%;
}

/* 头部--开始 */
.purchaseManage_mid .purchaseManage_header {
  /* width: 100%; */
  height: 134px;
  background-color: #fff;
}

.purchaseManage_header .title {
  display: flex;
  align-items: flex-end;
}

.purchaseManage_header .title img {
  width: 6px;
  height: 20px;
  margin: 20px 0 0 20px;
}

.purchaseManage_header .title p {
  font-size: 20px;
  font-family: "SHSCN_Bold";
  margin: 1vw 0 0 0.6vw;
}

/* 选项框和按钮 */
.option_btn {
  /* width: 100%; */
  height: 7.2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option_btn /deep/ .el-input {
  width: 206px;
  height: 32px;
}

.options {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.option_btn /deep/ .el-input__icon {
  line-height: 1.85vh;
}

.option_btn span {
  font-size: 16px;
  font-family: "SHSCN_Regular";
}

/* 头部--结束 */

/* 表格区域--开始 */
.purchaseManage_mid .gateway_form {
  position: relative;
  height: calc(100vh - 222px);
  width: 100%;
  background-color: #fff;
  margin-top: 1vh;
}

/* 表格区域--结束 */

.lists /deep/ .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
  margin-top: 28px;
  height: 50px !important;
}
</style>
